
export default {
    name: 'MyHeader',
    props: {
        msg: String,
        checkedMenu: Object
    },
    data() {
        return {
            form: {
                oldPassword: '',
                newPassword: '',
                newPassword2: ''
            },
            workChange: true,
            isFold: false,
            centerDialogVisible: false,
            username: '',
            nickname: '',
            menus: [],
            clickNum: 22,
            messageArr: [],
            userInfo: {},
            isCollapse: true
        };
    },
    methods: {
        otherClick(row, rowIndex) {
            localStorage.setItem('detailShow', 'show');
            // 先关闭该弹框
            this.noDeal(row, rowIndex);
            // 收起消息列表
            this.toggleDiv();
            // this.handleCase({
            //     caseNo: row.caseNo,
            //     menuTypeNum: row.caseStep
            // });
            const routeUrl = this.$router.resolve({
                path: '/workbench/myTask',
                query: { caseNo: row.caseNo, menuTypeNum: row.caseStep, type: 'handle' }
            });
            window.open(routeUrl.href, '_self');
        },
        handelCase(row, rowIndex) {
            localStorage.removeItem('detailShow');
            // 先关闭该弹框
            // this.noDeal(row, rowIndex);
            // 收起消息列表
            // this.toggleDiv();

            // 跳转对应案件详情
            // this.handleCase({
            //     caseNo: row.caseNo,
            //     menuTypeNum: row.caseStep,
            //     detailShow: 'null'
            // });
            const routeUrl = this.$router.resolve({
                path: '/workbench/myTask',
                query: { caseNo: row.caseNo, menuTypeNum: row.caseStep, detailShow: 'null', type: 'handle' }
            });
            window.open(routeUrl.href, '_self');
        },
        noDeal(row, rowIndex) {
            // 处理弹窗消息后需要屏蔽当前通知项
            this.instance.post('/tpa/api/case/notice/block', row).then((res) => {
                let data = res.data;
                if (data.code == 200 && data.data) {
                    // 删除数组
                    this.messageArr.splice(rowIndex, 1);
                } else {
                    console.log('屏蔽通知失败：' + row.caseNo);
                }
            });
        },
        clearMsg() {
            this.instance.post('/tpa/api/case/notice/clear', {}).then((res) => {
                let data = res.data;
                if (data.code == 200) {
                    this.messageArr = [];
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        toggleDiv() {
            this.isCollapse = !this.isCollapse;
            if (this.isCollapse && this.$refs['box1']) {
                this.$refs['box1'].style.height = 0;
                this.$refs['messageHot'].style.zIndex = 0;
            } else if (this.$refs['box1']) {
                this.$refs['box1'].style.height = '505px';
                this.$refs['messageHot'].style.zIndex = 12;
            }
        },
        copyCaseNo(caseNo) {
            let textarea = document.createElement('textarea'); //创建临时元素
            textarea.value = caseNo; //设置要复制的内容
            document.body.appendChild(textarea); //将该元素添加到页面上
            textarea.select(); //选中输入框中的内容
            try {
                let successful = document.execCommand('copy'); //执行复制命令
                if (successful) {
                    this.$message.success('报案号复制成功！');
                } else {
                    console.log('无法复制！');
                }
            } catch (err) {
                console.error('无法复制！', err);
            } finally {
                document.body.removeChild(textarea); //移除临时元素
            }
        },
        getNoticeList() {
            let _this = this;
            this.tmpMessageNum = this.messageArr.length;
            this.instance.post('/tpa/api/case/notice/list', {}).then((res) => {
                let data = res.data;
                if (data.code == 200) {
                    this.messageArr = data.data;

                    if (this.tmpMessageNum > 0 && this.tmpMessageNum != data.data.length) {
                        this.toggleDiv();
                        setTimeout(() => {
                            _this.toggleDiv();
                        }, 3000);
                    }

                    this.noticeListTime = setTimeout(() => {
                        _this.getNoticeList();
                    }, 60000);
                }
            });
        },
        switchChange(val) {
            console.log(val);
        },
        //操作修改密码
        handleEditPassword() {
            if (this.form.newPassword != this.form.newPassword2) {
                this.$message.error('抱歉，您两次输入的新密码不一致');
                return false;
            }
            var json = {
                oldPassword: this.form.oldPassword,
                newPassword: this.form.newPassword
            };
            let _this = this;
            this.instance.post('/upms/api/user/changePassword', json).then((res) => {
                var data = res.data;
                if (data.code == 200) {
                    this.$message.success(data.message);
                    setTimeout(() => {
                        _this.centerDialogVisible = false;
                        _this
                            .$confirm('密码修改成功，请重新登录。', '提示', {
                                confirmButtonText: '确定',
                                showCancelButton: false,
                                type: 'warning'
                            })
                            .then(() => {
                                let localVueVersion = localStorage.getItem('vueVersion');
                                localStorage.clear();
                                localStorage.setItem('vueVersion', localVueVersion);
                                _this.$router.replace({
                                    path: '/'
                                });
                            });
                    }, 3000);
                } else {
                    this.$message.error(data.message);
                }
            });
        },
        //操作退出
        handleLogOut() {
            this.$confirm('此操作将退出当前登录, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            })
                .then(() => {
                    this.instance.post('/upms/api/user/logout').then((res) => {
                        var data = res.data;
                        if (data.code == 200) {
                            let localVueVersion = localStorage.getItem('vueVersion');
                            localStorage.clear();
                            localStorage.setItem('vueVersion', localVueVersion);
                            sessionStorage.clear();
                            this.$router.push({ path: '/' });
                            location.reload();
                            this.$message({ type: 'success', message: '退出成功!' });
                        }
                    });
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消'
                    });
                });
        },
        //点击logo时的跳转
        linkHomePage() {
            // path: '/bigScreen/index',以前系统跳转路由，没在项目中找到对应文件
        },
        //点击面包屑，是否展开菜单
        handleFoldMenus(isFold) {
            this.isFold = isFold;
            this.$emit('handleFoldMenus', isFold);
        },
        clickPageLogo() {
            this.clickNum++;
            if (this.clickNum >= 13) {
                this.userMenuTree();
                document.onkeydown = null;
                this.clickNum = this.clickNum > 13 ? 0 : this.clickNum;
                localStorage.setItem('keyCode', 123);
            } else if (this.clickNum < 13) {
                document.onkeydown = function () {
                    var e = window.event || arguments[0];
                    //屏蔽F12
                    if (e.keyCode == 123) {
                        return false;
                        //屏蔽Ctrl+Shift+I
                    } else if (e.ctrlKey && e.shiftKey && e.keyCode == 73) {
                        return false;
                        //屏蔽Shift+F10
                    } else if (e.shiftKey && e.keyCode == 121) {
                        return false;
                    }
                };
                localStorage.removeItem('keyCode');
            }

            if (this.clickNum == 10) {
                console.log('等于10');
            }
        },
        userMenuTree() {
            this.instance
                .get('/upms/api/menu/getMenuTree', this.form)
                .then((res) => {
                    localStorage.setItem('userMenu', JSON.stringify(res.data.data));
                })
                .catch((res) => {
                    console.log(res);
                });
        }
    },
    activated() {
        this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
        this.username = this.userInfo.username || '匿名用户';
        this.nickname = this.userInfo.nickname;
    },
    mounted() {
        if (localStorage.getItem('keyCode')) {
            document.onkeydown = null;
        } else {
            document.onkeydown = function () {
                var e = window.event || arguments[0];
                //屏蔽F12
                if (e.keyCode == 123) {
                    return false;
                    //屏蔽Ctrl+Shift+I
                } else if (e.ctrlKey && e.shiftKey && e.keyCode == 73) {
                    return false;
                    //屏蔽Shift+F10
                } else if (e.shiftKey && e.keyCode == 121) {
                    return false;
                }
            };
        }
        this.getNoticeList();
    }
};
