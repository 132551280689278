
import MyHeader from '@/components/MyHeader.vue';
import MyMenus from '@/components/MyMenus.vue';

export default {
    name: 'Layout',
    components: {
        MyHeader,
        MyMenus
    },
    computed: {
        key() {
            return this.$route.path;
        }
    },
    data() {
        return {
            isCollapse: false,
            isVisible: true,
            isShow: true,
            getDates: null,
            dateTime: null,
            containerWidth: 0,
            containerLeft: 0,
            contentHeight: '90%',
            checkedMenu: { name: '设备管理', id: '30' }
        };
    },
    beforeUpdate() {
        let _this = this;
        this.calcContentHeight();
        window.addEventListener('resize', function () {
            _this.calcContentHeight();
            _this.containerWidth = document.body.clientWidth - _this.containerLeft;
            const rightDom = document.getElementById('sub');
            rightDom.style.width = _this.containerWidth + 'px';
        });
    },
    activated() {
        this.calcContentHeight();
    },
    mounted() {
        let _this = this;
        this.calcContentHeight();
        window.addEventListener('resize', function () {
            _this.calcContentHeight();
            _this.containerWidth = document.body.clientWidth - _this.containerLeft;
            const rightDom = document.getElementById('sub');
            rightDom.style.width = _this.containerWidth + 'px';
        });
        this.isShow = this.$route.path != '/olap/largeScreen';

        localStorage.removeItem('tmpCaseStep');
    },
    updated() {
        this.isShow = this.$route.path != '/olap/largeScreen';
    },
    methods: {
        handleFoldMenus(fold) {
            this.isCollapse = fold;
        },
        calcContentHeight() {
            let headerHeight = 60;
            let guHeight = 2;
            let pageTitleHeight = 0;
            if (this.$route.path.indexOf('/olap') != -1) {
                guHeight = 10;
            }
            if (this.$route.path.indexOf('/olap/dailyAnalysis') != -1) {
                guHeight = 8;
            }
            if (this.$route.path.indexOf('/system') != -1) {
                guHeight = 4;
            }

            this.contentHeight = window.innerHeight - headerHeight - guHeight + 'px';
            this.contentHeight = this.contentHeight < 300 ? 300 : this.contentHeight;
        },
        getData() {
            let timestamp = Date.now();
            this.dateTime = timestamp;
            this.getDates = setTimeout(this.getData, 3000);
        },
        reload() {
            this.isVisible = false;
            this.$nextTick(function () {
                this.isVisible = true;
            });

            console.log('调用更新');
            localStorage.removeItem('version');
        },
        MenuResize(data) {
            // data = {width:'64px', height:'43.19px' targetId:''}
            // 动态设置子应用margin-left
            const rightDom = document.getElementById('sub');
            let tmpIndex = data.width.indexOf('p');
            let widthStr = data.width.slice(0, tmpIndex);
            this.containerLeft = Number(widthStr);
            rightDom.style.marginLeft = Number(widthStr) / 2 + 'px';
            rightDom.style.width = document.body.clientWidth - Number(widthStr) + 'px';
        },
        heightResize(data) {
            // data = {width:'64px', height:'43.19px' targetId:''}
            const rightDom = document.getElementById('sub');
            let tmpHeightIndex = data.height.indexOf('p');
            let heightStr = data.height.slice(0, tmpHeightIndex);
            rightDom.style.marginTop = Number(heightStr) / 2 + 'px';
            rightDom.style.height = document.body.clientHeight - Number(heightStr) + 'px';
        },
        handleFoldMenus(isFold) {
            this.isCollapse = isFold;
        }
    },
    watch: {
        $route(to, from) {
            // 对路由变化作出响应...
            if (to.name == from.name) {
                this.isVisible = false;
                setTimeout(() => {
                    this.isVisible = true;
                }, 200);
            }
        },
        dateTime(val) {
            if (this.$$isNewVersion() || localStorage.getItem('version') == 'true') {
                console.log('watch:' + this.$$isNewVersion());
                this.reload();
            }
        }
    }, // 监听左侧菜单自动被撑开时宽度变化
    directives: {
        resize: {
            // 指令的名称
            bind(el, binding) {
                // el为绑定的元素，binding为绑定给指令的对象
                // console.log(el, "绑定", binding);
                let _width = '';
                let _height = '';
                function isReize() {
                    const style = document.defaultView.getComputedStyle(el);
                    if (_width !== style.width) {
                        binding.value({ width: style.width, height: style.height, targetId: el.id });
                        _width = style.width;
                    }
                }
                el.__vueSetInterval__ = setInterval(isReize, 300);
            },
            unbind(el) {
                clearInterval(el.__vueSetInterval__);
            }
        },
        heightResize: {
            // 指令的名称
            bind(el, binding) {
                // el为绑定的元素，binding为绑定给指令的对象
                // console.log(el, "绑定", binding);
                let _width = '';
                let _height = '';
                function isReize() {
                    const style = document.defaultView.getComputedStyle(el);
                    if (_width !== style.width) {
                        binding.value({ width: style.width, height: style.height, targetId: el.id });
                        _width = style.width;
                    }
                }
                el.__vueSetInterval__ = setInterval(isReize, 300);
            },
            unbind(el) {
                clearInterval(el.__vueSetInterval__);
            }
        }
    }
};
