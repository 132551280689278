import Vue from "vue";
import VueRouter from "vue-router";
import Layout from "@/layout";
import store from "../store";
import utils from "../utils/auth.js";
Vue.use(VueRouter);

let routes = [
    {
        path: "/vue/*",
        component: Layout,
    },
    {
        path: "/",
        name: "Login",
        meta: {
            title: "宏瓴科技",
        },
        component: () => import("../views/login/index.vue"),
    },
];

let resetFlag = false;
// 动态重置路由
function resetRoutes(routesData) {
    // console.log('resetRoutes')
    localStorage.setItem("resetRoutes", "");

    routes = [
        {
            path: "/vue/*",
            component: Layout,
        },
        {
            path: "/",
            name: "Login",
            meta: {
                title: "宏瓴科技",
            },
            component: () => import("../views/login/index.vue"),
        },
    ];
    let userMenu =
        routesData || JSON.parse(localStorage.getItem("userMenu")) || []; //路由数据
    let stagingArr = [];
    for (let i = 0; i < userMenu.length; i++) {
        let tmpUserMenu = userMenu[i];
        let tmpRoute = {
            path: tmpUserMenu.path,
            redirect:
                tmpUserMenu.path.slice(1, tmpUserMenu.path.length) + "/index",
            component: Layout,
            name: tmpUserMenu.path.slice(1, tmpUserMenu.path.length),
            meta: {
                title: tmpUserMenu.menuName,
                icon: tmpUserMenu.icon,
                menuId: tmpUserMenu.menuId,
            },
            children: [],
        };

        let childrenItem = {};
        if (tmpUserMenu.children && tmpUserMenu.children.length > 0) {
            for (let t = 0; t < tmpUserMenu.children.length; t++) {
                const tmpItem = tmpUserMenu.children[t];

                if (tmpItem.menuName == "待审案件") {
                    stagingArr.push({ name: "立案任务", type: "30" });
                }
                if (tmpItem.menuName == "待定损案件") {
                    stagingArr.push({ name: "定损任务", type: "40" });
                }
                if (tmpItem.menuName == "待核赔案件") {
                    stagingArr.push({ name: "核赔任务", type: "50" });
                }
                if (tmpItem.menuName == "预报案案件") {
                    stagingArr.push({ name: "预报案任务", type: "15" });
                }
                localStorage.setItem("stagingArr", JSON.stringify(stagingArr));

                tmpItem.path = tmpItem.path.slice(1, tmpItem.path.length);
                childrenItem = {
                    path: tmpItem.path,
                    name: tmpItem.path,
                    meta: {
                        title: tmpItem.menuName,
                        menuId: tmpItem.menuId,
                        perms:
                            tmpItem.perms != tmpUserMenu.menuName
                                ? tmpItem.perms
                                : null,
                        component: tmpItem.component,
                    },
                };
                if (tmpUserMenu.path != "/vue") {
                    childrenItem.component = (resolve) =>
                        require([
                            "@/views" +
                                tmpItem.component +
                                "/" +
                                tmpItem.path +
                                ".vue",
                        ], resolve);
                }

                tmpRoute.children.push(childrenItem);
            }
        } else {
            childrenItem = {
                path: "index",
                name: tmpUserMenu.menuName,
                meta: {
                    title: tmpUserMenu.menuName,
                    menuId: tmpUserMenu.menuId,
                    component: tmpUserMenu.component,
                },
            };
            childrenItem.component = (resolve) =>
                require(["@/views" + tmpUserMenu.path + "/index"], resolve);
            tmpRoute.children.push(childrenItem);
        }
        routes.push(tmpRoute);
    }
    // console.log('整理路由：')
    // console.log(routes)
    localStorage.setItem("routes", JSON.stringify(routes));
    routes.forEach((v) => {
        router.addRoute(v);
    });
}

const router = new VueRouter({
    mode: "history",
    // base: process.env.BASE_URL,
    routes,
});

// 路由守卫
let oneRun = true;
resetRoutes();
router.beforeEach((to, from, next) => {
    if (to.meta.title) {
        document.title = to.meta.title;
    }

    if (from.path == "/") {
        localStorage.setItem("resetRoutes", true);
    }

    if (
        to.fullPath.indexOf("redirect") != -1 &&
        localStorage.getItem("tokenError") == 401
    ) {
        next({
            replace: true,
            name: "首页",
            path: "/",
        });
    }

    if (utils.getToken() && oneRun) {
        setTimeout(() => {
            oneRun = true;
        }, 100);
        oneRun = false;

        if (to.path == "/") {
            setTimeout(() => {
                next({
                    replace: true,
                    name: "caseInquiry",
                    path: "/caseInquiry/index",
                });
            }, 200);
        } else {
            next();
        }
    } else {
        if (to.path == "/") {
            next();
        } else {
            next({
                replace: true,
                name: "Login",
            });
        }
    }
});

/** 解决跳转重复路由报错问题   */

const originPush = router.push;
VueRouter.prototype.push = function (location, resolve, reject) {
    if (resolve && reject) {
        originPush.call(this, location, resolve, reject);
    } else {
        originPush.call(
            this,
            location,
            () => {},
            () => {}
        );
    }
};

export function handleMenuData(data) {
    data && resetRoutes(data);
}

export default router;
