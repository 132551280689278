import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        version: "manager", //manager
        userMenu: false,
    },
    getters: {
        baseTitle(state) {
            var version = state.version;
            return "京东物流TPA";
        },
        baseURL(state) {
            let href = "//" + location.hostname + "/manager";
            if (location.hostname == "localhost") {
                href = "//cargo-tpa.test.sigreal.cn/manager";
                // href = "//cargo-tpa.test-pre.sigreal.cn/manager";
                // href = "https://jd-tpa.pre.sigreal.cn/manager";
            }
            return href;
        },
    },
    mutations: {},
    actions: {},
    modules: {},
});
