
import { handleMenuData } from './router/index.js';
export default {
    data() {
        return {
            subApplication: true
        };
    },
    mounted() {
        localStorage.getItem('AUTH_TOKEN') && this.userMenuTree();
    },
    methods: {
        userMenuTree() {
            this.instance
                .get('/upms/api/menu/getMenuTree', this.form)
                .then((res) => {
                    let data = res.data;
                    if (data.code == 200) {
                        localStorage.setItem('userMenu', JSON.stringify(data.data));
                        handleMenuData(data.data);
                    } else if (localStorage.getItem('X-From-Business-Line') != 'tpa') {
                        localStorage.clear();
                        this.$router.push({ path: '/' });
                    }
                })
                .catch((res) => {
                    console.log(res);
                });
        }
    },
    watch: {
        $route(val) {
            localStorage.removeItem('queryFrom');
            localStorage.removeItem('editConfirmAmount');
            if (localStorage.getItem('userInfo') && val.path != '/') {
                let tmpUserInfo = JSON.parse(localStorage.getItem('userInfo'));
                this.$$warterMark.set(this.$$getDate(), tmpUserInfo.nickName);
            } else {
                this.$$warterMark.remove();
            }
        }
    }
};
